<template>
  <div class="pt-12">
    <div class="main-uploaded-image-container" :class="{ 'image-open': isImageOpen }">
      <div class="uploaded-image-container">
        <cropper ref="cropper"
                 :stencil-props="{ aspectRatio: aspectRatio }"
                 :stencil-component="CircleStencil"
                 class="cropper"
                 :src="image.src"
        />
        <div class="vertical-buttons" v-if="image && image.src">
          <div class="square-button rounded" @click="zoom(1.1)">
            <v-icon
                dark
            >
              {{ icons.magnifyPlus }}
            </v-icon>
          </div>
          <div class="square-button rounded" @click="zoom(0.9)">
            <v-icon
                dark
            >
              {{ icons.magnifyMinus }}
            </v-icon>
          </div>
          <button class="square-button rounded" @click.prevent="cropImage()">
            <v-icon
                dark
            >
              {{ icons.check }}
            </v-icon>
          </button>
          <button class="square-button rounded" @click="removeImage()">
            <v-icon
                dark
            >
              {{ icons.close }}
            </v-icon>
          </button>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <button class="button" @click="$refs.file.click()">
        <input
            type="file"
            ref="file"
            @change="uploadImage($event)"
            accept="image/*"
        />
        Upload image
      </button>
    </div>
  </div>
</template>

<script>
import {CircleStencil, Cropper} from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import {mdiMagnifyPlusOutline, mdiMagnifyMinusOutline, mdiCheck, mdiClose} from "@mdi/js";

export default {
  name: "PictureCrop",

  data() {
    return {
      isImageOpen: false,
      image: {
        src: '',
        type: "image/jpg",
      },
      aspectRatio: "1",
      imageCropped: '',
      icons: {
        magnifyPlus: mdiMagnifyPlusOutline,
        magnifyMinus: mdiMagnifyMinusOutline,
        check: mdiCheck,
        close: mdiClose,
      },
    };
  },
  computed: {
    CircleStencil() {
      return CircleStencil
    },
    showCircleStencil() {
      return 'circle-stencil'
    },
  },
  methods: {

    cropImage() {
      const result = this.$refs.cropper.getResult();

      let canvasResult = result.canvas.toDataURL(
          this.image.type
      )

      // Original Image
      fetch(canvasResult)
          .then(res => res.blob())
          .then(blob => {
            let newFileFromCrop = new File([blob], "File name", {type: "image/png"})

            console.log('CroppedFile', newFileFromCrop);

            this.$emit('updateImage', {
                  croppedFile: newFileFromCrop,
                }
            );
          })

      this.imageCropped = canvasResult

      this.removeImage();
    },
    uploadImage(event) {
      this.isImageOpen = true;
      /// Reference to the DOM input element
      const {files} = event.target;
      // Ensure that you have a file before attempting to read it
      if (files && files[0]) {
        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.image.src) {
          URL.revokeObjectURL(this.image.src);
        }
        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(files[0]);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.image = {
          src: blob,
          type: files[0].type,
        };
      }
    },

    zoom(factor) {
      this.$refs.cropper.zoom(factor);
    },
    removeImage() {
      this.image.src = '';
      this.$refs.file.value = '';
      this.isImageOpen = false;
    },
  },
  unmounted() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.image.src) {
      URL.revokeObjectURL(this.image.src);
    }
  },
  components: {
    Cropper
  },
};
</script>

<style lang="scss">
.main-uploaded-image-container {
  max-width: 20% !important;
  margin: 0 auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 20000;


  .uploaded-image-container {
    position: relative;
    max-width: 80% !important;

    @media (min-width: 1200px) {
      max-width: 30% !important;
    }

    .vue-advanced-cropper {
      max-width: 100%;
    }

    .crop-buttons {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 0;
      color: white;
      font-size: 16px;
      cursor: pointer;
    }

    .vertical-buttons {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);

      .horizontal-buttons {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }

      .square-button {
        background: rgba(black, 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 42px;
        width: 42px;
        margin-bottom: 10px;
        cursor: pointer;
        transition: background 0.5s;
        color: #fff;

        &:hover {
          background: black;
        }
      }
    }
  }

  &.image-open {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgb(0 0 0 / 20%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    max-width: 100% !important;
  }
}


.cropper {
  min-height: 300px;
  width: 100%;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 17px;
}

.button {
  color: white;
  font-size: 16px;
  padding: 10px 20px;
  width: 100%;
  background: #151515;
  cursor: pointer;
  transition: background 0.5s;
  border: none;
}

.button:not(:last-of-type) {
  margin-right: 10px;
}

.button:hover {
  background: #2F2F2F;
}

.button input {
  display: none;
}
</style>
