import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import vuetify from '@/vuetify' // Import the Vuetify plugin



import router from './router' // Import the router
import 'material-design-icons-iconfont/dist/material-design-icons.css';


//const app = createApp(App).component("font-awesome-icon", FontAwesomeIcon)
const app = createApp(App)

app.use(createPinia())
app.use(vuetify) // Use Vuetify
app.use(router) // Use the router
app.mount('#app')