<template>
  <v-app>
    <v-container class="fill-height mx-auto" fluid :style="{ maxWidth: '1200px' }">
      <div v-if="loading" class="loading-container">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </div>
      <v-row justify="center" align="center">

        <v-card flat tile>
          <v-container fluid>
            <v-row justify="center" align="center">
              <v-col cols="12" sm="2" md="4" lg="2" v-for="(category, index) in categories"
                     :key="index">
                <!-- Repeat this v-col for each category -->
                <v-card class="pa-3 centered-content" outlined tile
                        @mouseover="category.hovering = true"
                        @mouseleave="category.hovering = false"
                >
                  <div
                      class="circle-image"
                      :style="{
          backgroundImage: 'url(' + (category.hovering ? category.hover : category.image) + ')',
      }"
                  ></div>
                  <v-card-title class="text-h6 text-center">
                    {{ category.name }}
                  </v-card-title>
                </v-card>
              </v-col>

            </v-row>
          </v-container>
        </v-card>
      </v-row>
    </v-container>
  </v-app>
</template>

<script setup>
import {onMounted, reactive, ref} from "vue";

const categories = reactive([]);
const loading = ref(true); // Indicates whether auctions are being loaded
const fetchCategories = async () => {
  loading.value = true; // Start loading
  try {
    const response = await fetch('https://apitest.artcromatic.ro/api/categories'); // Replace with your API endpoint
    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }
    const data = await response.json();
    console.log('Fetched data:', data); // Log to see the structure of the fetched data

    // Update this line according to the structure of your data
    categories.splice(0, categories.length, ...data.map(category => ({...category, hovering: false})));

    console.log('Updated categories:', categories); // Log to check if categories is updated
  } catch (error) {
    console.error('Error fetching categories:', error);
    // Handle error appropriately
  }

  loading.value = false; // Start loading
};

onMounted(() => {
  fetchCategories();
});
// ... existing script setup ...
</script>

<style scoped>
.blue-background {
  background-color: #333;
  color: white;
}

.centered-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.v-card-title {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
}
</style>