<template>
  <v-app>
    <v-app-bar app v-if="authToken">
      <!-- Logo and Search Input on the left -->
      <div class="d-flex justify-center align-center pl-4">
        <v-toolbar-title>
          <img src="https://artcromatic.s3.eu-central-1.amazonaws.com/logos/logo-artcromatic@2x.svg" alt="Logo"
               height="40">
        </v-toolbar-title>
        <v-btn icon @click="showDialog = true" class="ml-4">
          <v-icon>{{ mdiMagnify }}</v-icon>
        </v-btn>

        <!-- Search Dialog -->

          <v-dialog   v-model="showDialog" persistent max-width="600px" style="top: 100px !important;">
            <v-card>

              <v-card-text>
                <v-text-field
                    v-model="searchQuery"
                    label="Cauta"
                    outlined
                    autofocus
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :prepend-icon="mdiCancel"  class="font-weight-bold" color="error" @click="showDialog = false">Anuleaza</v-btn>
                <v-btn :prepend-icon="mdiSearchWeb" class="font-weight-bold ml-5" color="blue" @click="performSearch">Cautare</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


      </div>
      <!-- Spacer to push buttons to the right -->
      <v-spacer></v-spacer>
      <!-- AICI E MENIUL DE TEST -->

      <!-- SFARSIT MENIU DE TEST -->
      <!-- Buttons on the right -->
      <div class="text-center">
        <v-menu
            :close-on-content-click="false"
            location="end"
            bottom
            left
            :nudge-width="200"
            :nudge-top="10"
            :nudge-right="10"
        >
          <template v-slot:activator="{ props }">
            <v-btn
                :icon="mdiAccount"
                color="black"
                v-bind="props"
                elevation="2"
                size="small"
            >

            </v-btn>
          </template>

          <v-card min-width="300" style="top: 52px;">
            <v-list>
              <v-list-item
                  prepend-avatar="https://cdn.vuetifyjs.com/images/john.jpg"
                  title="John Leider"
                  subtitle="Founder of Vuetify"
              >
                <template v-slot:append>
                  <v-btn
                      variant="text"
                      :class="fav ? 'text-red' : ''"
                      :icon="mdiHeart"
                      @click="fav = !fav"
                  ></v-btn>
                </template>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item>
                <v-switch
                    v-model="message"
                    color="purple"
                    label="Enable messages"
                    hide-details
                ></v-switch>
              </v-list-item>

              <v-list-item>
                <v-switch
                    v-model="hints"
                    color="purple"
                    label="Enable hints"
                    hide-details
                ></v-switch>
              </v-list-item>
            </v-list>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  variant="text"
                  @click="menu = false"
              >
                Cancel
              </v-btn>
              <v-btn
                  color="error"
                  :icon="mdiPower"
                  variant="text"
                  @click="showLogoutConfirm = true"
              >
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
        <v-dialog v-model="showLogoutConfirm" persistent max-width="300px">
          <v-card>
            <v-card-text>Confirmi ca vrei sa te deloghezi de pe ArtCromatic?</v-card-text>
            <v-card-actions>
              <v-btn color="grey" class="font-weight-bold" text @click="showLogoutConfirm = false">Anulez</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="red" class="font-weight-bold"  text @click="confirmLogout">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-app-bar>
    <router-view></router-view>
  </v-app>
</template>

<script>
import {mdiMagnify} from "@mdi/js";
import {mdiLogin} from "@mdi/js";
import {mdiCogOutline} from "@mdi/js";
import {useRouter} from 'vue-router';
import {useAuthStore} from '@/store/authStore';
import {computed, ref} from "vue";
import {mdiDotsVertical} from "@mdi/js";
import {mdiCancel} from "@mdi/js";
import {mdiSearchWeb} from "@mdi/js";
import {mdiAccount} from "@mdi/js";
import {mdiPower} from "@mdi/js";
import {mdiHeart} from "@mdi/js";
const authToken = computed(() => useAuthStore().token); // Ensure this is correctly getting the auth token
export default {
  name: 'App',
  components: {},
  data() {
    return {
      mdiMagnify,
      mdiLogin,
      mdiCogOutline,
      mdiDotsVertical,
      mdiCancel,
      mdiSearchWeb,
      mdiAccount,
      mdiPower,
      mdiHeart,
      items: [
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me'},
        {title: 'Click Me 2'},
      ],
      fav: true,
      menu: false,
      message: false,
      hints: true,

    };
  },
  setup() {
    const router = useRouter();
    const showLogoutConfirm = ref(false);
    const login = () => {
      // Navigate to the login route
      router.push({name: 'Login'}); // Replace 'Login' with the actual route name for your login page
    };
    const logout = () => {
      useAuthStore().logout(); // Clears the token and other authentication data
      router.push('/login'); // Redirects to the login page
    };

    const isProfileLoaded = computed(() => useAuthStore().isProfileLoaded);
    const showDialog = ref(false);
    const searchQuery = ref("");
    const performSearch = () => {
      console.log("Searching for:", searchQuery.value);
      // Add your search logic here
      showDialog.value = false; // Close the dialog after search
    };
    const confirmLogout = () => {
      showLogoutConfirm.value = false; // Close the confirmation dialog
      logout(); // Call the existing logout method
    };
    return {
      login,
      logout,
      isProfileLoaded,
      showDialog,
      searchQuery,
      performSearch,
      authToken,
      confirmLogout,
      showLogoutConfirm
    };
  }
}
</script>

<style>
html {
  font-family: 'Poppins', sans-serif;
}

body {

}

#app {
  font-family: 'Poppins', sans-serif !important;
}

.blue-border {
  border: 1px solid #e18b04 !important;
}

@media only screen and (max-width: 400px) {
  .hide-on-mobile {
    display: none;
  }
}

.custom-mobile-display {
  display: flex;
}

@media only screen and (min-width: 400px) {
  .custom-mobile-display {
    display: none !important;
  }
}
</style>
