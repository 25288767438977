<template>
  <v-container>
    <v-row justify="center" align="center" style="height: 100vh;">
      <v-col cols="12" sm="8" md="6" lg="4">
        <div v-if="showRegistrationMessage" class="registration-success-message">
          Registered successfully. Please log in.
        </div>
        <v-snackbar
            v-model="snackbar"
            :color="snackbarType === 'error' ? 'red' : 'green'"
            :timeout="1000000"
            class="top-snackbar"
            top
        >
          <div class="d-flex justify-space-between align-center" style="width: 100%;">
            <span>{{ snackbarMessage }}</span>
            <v-btn variant="text" @click="snackbar = false">
              X
            </v-btn>
          </div>
        </v-snackbar>
        <div class="text-center">

          <div>
            <v-img
                class="mx-auto my-6"
                max-width="100"
                src="https://artcromatic.s3.eu-central-1.amazonaws.com/logos/logo-artcromatic@2x.svg"
            ></v-img>
            <v-card
                class="mx-auto pa-4 pb-8"
                elevation="8"
                max-width="448"
                rounded="lg"
            >
              <v-card-title class="text-h5 text-center">Autentificare</v-card-title>

              <form @submit.prevent="onSubmit">
                <div class="text-subtitle-1 text-medium-emphasis text-left"><span class="text-black">Adresa de e-mail</span></div>
              <v-text-field
                  v-model="email"
                  density="compact"
                  placeholder="Email address"
                  variant="outlined"
                  :prepend-inner-icon="mdiEmailOutline"
                  :rules="emailRules"
              ></v-text-field>

              <div class="text-subtitle-1 text-medium-emphasis d-flex align-center justify-space-between">
                <span class="text-black">Parola</span>
                <a
                    class="text-caption text-decoration-none text-orange"
                    href="#"
                    rel="noopener noreferrer"
                    target="_blank"

                >
                  <span>Am uitat parola</span></a>
              </div>

              <v-text-field
                  v-model="password"
                  density="compact"
                  placeholder="Enter your password"
                  variant="outlined"
                  :append-inner-icon="visible ? mdiEyeOff : mdiEye"
                  @click:append-inner="visible = !visible"
                  :type="visible ? 'text' : 'password'"
                  :prepend-inner-icon="mdiLock"
                  :rules="passwordRules"
              ></v-text-field>

              <v-card
                  class="mb-5"
                  color="surface-variant"
                  variant="tonal"
              >
              </v-card>

              <v-btn
                  type="submit"
                  block
                  class="mb-8 font-weight-bold"
                  color="orange"
                  size="large"
                  variant="tonal"
                  :loading="isLoading"
                  :disabled="isLoading"

              >
                Autentificare
              </v-btn>
                <v-card-text class="text-center">
                  <router-link
                      class="text-orange text-decoration-none"
                      to="/register"
                  >
                  Inregistreaza-te <v-icon :icon="mdiChevronRight"></v-icon>
                  </router-link>
                </v-card-text>
              </form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
<script setup>
import {  mdiEmailOutline, mdiEye, mdiEyeOff, mdiLock, mdiChevronRight } from '@mdi/js';
import {ref, onMounted} from 'vue';
import { useAuthStore } from '@/store/authStore';
import {useRoute, useRouter} from 'vue-router';

const route = useRoute();
const showRegistrationMessage = ref(false);

const auth = useAuthStore();
const email = ref('');
const password = ref('');
const isLoading = ref(false);  // Loading state
const snackbar = ref(false);
const snackbarMessage = ref('');
const snackbarType = ref('');  // New property to track the type of snackbar message
const visible = ref(false)
const router = useRouter(); // Get the router instance

const emailRules = [
  v => !!v || 'Email is required',
  v => /.+@.+\..+/.test(v) || 'Email must be valid'
];

const passwordRules = [
  v => !!v || 'Password is required' // Check if the password is not empty
];

onMounted(() => {
  // Check if the 'registered' query parameter is set to 'true'
  showRegistrationMessage.value = route.query.registered === 'true';
});

const onSubmit = async () => {
  isLoading.value = true;
  try {
    await auth.login(email.value, password.value);
    snackbarMessage.value = "Login successful!";
    snackbarType.value = 'success';  // Set to 'success' on successful login
    await router.push('/auctions/new'); // Redirects to the login page
  } catch (error) {
    // Extracting message from error object
    snackbarMessage.value = error.message;
    snackbarType.value = 'error';  // Set to 'error' on login failure
  } finally {
    isLoading.value = false;
    snackbar.value = true;
  }
};

</script>

<style>
.top-snackbar .v-snackbar--variant-elevated {
  position: fixed;
  bottom: 100% !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000; /* Ensure it's above other content */
}

.registration-success-message {
  background-color: green; /* Green background */
  color: white;           /* White text */
  padding: 10px;          /* Some padding for better appearance */
  margin-bottom: 15px;    /* Margin for spacing */
  border-radius: 5px;     /* Optional: rounded corners */
  text-align: center;     /* Center the text */
}
</style>