<template>
  <v-container>
    <v-row justify="center" align="center" class="fill-height">
      <v-col cols="12" class="text-center">
        <h1>Creează licitație</h1>
      </v-col>
    </v-row>

    <!-- Row for Image Uploader and Form -->
    <v-row>
      <!-- Image Uploader Component on the left -->
      <v-col cols="12" md="4">
        <image-uploader></image-uploader>
      </v-col>

      <!-- Form on the right -->
      <v-col cols="12" md="8">
        <v-card class="pa-5" elevation="2">
          <v-form ref="form" v-model="valid" class="create-auction">
            <v-text-field
                v-model="auctionData.title"
                label="Titlu"
                :rules="[required]"
                required
            ></v-text-field>
            <v-textarea
                v-model="auctionData.content"
                label="Descriere"
                required
                :rules="[required]"
            ></v-textarea>
            <v-row>
              <v-col cols="6">
                <v-select
                    v-model="auctionData.category_id"
                    :items="categories"
                    item-value="id"
                    item-title="name"
                    label="Categorie"
                    required
                    :rules="[required]"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-select
                    v-model="auctionData.currency"
                    :items="currencies"
                    label="Valuta"
                    required
                    :rules="[required]"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field
                    v-model="auctionData.start_price"
                    label="Preț de pornire"
                    type="number"
                    required
                    :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="auctionData.min_bid"
                    label="Ofertă minimă"
                    type="number"
                    required
                    :rules="[required]"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                    v-model="auctionData.max_bid"
                    label="Ofertă maximă"
                    type="number"
                    required
                    :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-select
                    v-model="auctionData.status"
                    :items="statuses"
                    item-value="value"
                    item-title="text"
                    label="Status"
                    required
                    :rules="[required]"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                    v-model="auctionData.end_timestamp"
                    label="Date încheiere"
                    type="datetime-local"
                    required
                    :rules="[required]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn :loading="loading" :disabled="!valid" @click="submitForm">
              Trimite
            </v-btn>
            <v-btn @click="handleReset" class="ml-4">
              Resetează
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- Display error messages -->
    <v-row>
      <v-col cols="12">
        <v-alert v-for="(error, index) in processedErrors" :key="index" type="error">
          <strong>{{ error.field }}:</strong>
          <div v-for="(message, index) in error.messages" :key="index">
            {{ message }}
          </div>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>


<script setup>
import {computed, onMounted, ref} from 'vue';
import {useAuctionsStore} from '@/store/auctions';
import { nextTick } from 'vue';
import imageUploader from "@/components/ImageUploader.vue";
import {useRouter} from 'vue-router';
const router = useRouter();

const auctionData = ref({
  title: '',
  content: '',
  category_id: null,
  currency: '',
  start_price: null,
  min_bid: null,
  max_bid: null,
  status: null,
  end_timestamp: ''
});

const currencies = ['RON', 'USD', 'EUR']; // Add other currencies as needed
const valid = ref(false);
const form = ref(null);
const loading = ref(false);
const required = (value) => !!value || 'This field is required.';

const auctionsStore = useAuctionsStore();
const submitError = ref(""); // Reactive variable to store the error message
const processedErrors = computed(() => {
  return Object.entries(submitError.value).reduce((acc, [field, messages]) => {
    if (messages.length) {
      acc.push({field, messages});
    }
    return acc;
  }, []);
});
const submitForm = async () => {
  submitError.value = "";
  loading.value = true; // Start loading

  if (valid.value && form.value.validate()) {
    // Convert end_timestamp to a Date object
    const endTimestampDate = new Date(auctionData.value.end_timestamp);

    // Construct the date parts, ensuring two digits for month, day, hour, minute, and second
    const year = endTimestampDate.getFullYear();
    const month = ('0' + (endTimestampDate.getMonth() + 1)).slice(-2); // getMonth() is zero-based
    const day = ('0' + endTimestampDate.getDate()).slice(-2);
    const hours = ('0' + endTimestampDate.getHours()).slice(-2);
    const minutes = ('0' + endTimestampDate.getMinutes()).slice(-2);
    const seconds = ('0' + endTimestampDate.getSeconds()).slice(-2);

    // Format the date in "YYYY-MM-DD HH:MM:SS"
    // Update the end_timestamp in auctionData before submitting
    auctionData.value.end_timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;



    try {
      // Attempt to submit the auction data and catch the response
      const response = await auctionsStore.submitAuctionData(auctionData.value);
      // Handle the successful submission here
      if (response && response.id) {
        //await router.push({name: 'ViewAuction', params: {auctionId: response.id}});
        await router.push(
            {
              path: `/auctions/${response.id}`,
              query: { success: 'true' }
            }
        );
      } else {
        console.error('Auction created but no ID returned');
      }
      console.log('Submission zcfff:', response);

      // Optionally, you can clear the form or redirect the user to another page

    } catch (error) {
      // Parse the error message and set the submitError
      try {
        const errorObject = JSON.parse(error.message);

        if (errorObject) {
          submitError.value = errorObject;
        } else {
          // Handle unexpected error format
          submitError.value = {general: ["An unexpected error occurred"]};
        }
      } catch (e) {
        // Handle case where error message is not JSON
        submitError.value = {general: [error.message || "An unexpected error occurred"]};
      } finally {
        loading.value = false; // Stop loading regardless of success or error
      }
    }

  } else {
    loading.value = false; // Stop loading if validation fails
  }
};

let categories = ref([]);

const statuses = [
  {text: 'Activ', value: 1},
  {text: 'Inactiv', value: 0}
];

const fetchCategories = async () => {
  try {
    const response = await fetch('https://apitest.artcromatic.ro/api/categories');
    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }
    const data = await response.json();
    // Assuming each category has 'id' and 'name' properties
    categories.value = data.map(cat => ({id: cat.id, name: cat.name}));    //categories = data
  } catch (error) {
    console.error('Error fetching categories:', error);
    // Handle error appropriately
  }
};
const handleReset = () => {
  auctionData.value = {
    title: '',
    content: '',
    category_id: null,
    currency: '',
    start_price: null,
    min_bid: null,
    max_bid: null,
    status: null,
    end_timestamp: ''
  };

  submitError.value = "";

  // Reset form validation state with a slight delay to ensure reactivity system updates
  nextTick(() => {
    if (form.value) {
      form.value.resetValidation();
    }
  });
};
onMounted(() => {
  fetchCategories();
});
</script>

<style>
.create-auction .v-field--variant-filled .v-field__overlay {
  background: none !important;
}

</style>