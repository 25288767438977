import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LoginView.vue' // Import your components
import RegisterView from "@/views/RegisterView.vue";
import HomeView from "@/views/HomeView.vue";
import CreateAuction from "@/views/Auction/CreateAuction.vue";
import {useAuthStore} from "@/store/authStore";
import PublicAuctions from "@/views/Auction/PublicAuctions.vue";
import ViewAction from "@/views/Auction/ViewAction.vue";
import EditAuction from "@/views/Auction/EditAuction.vue";
import PictureCrop from "@/components/PictureCrop.vue";
import ImageUploader from "@/components/ImageUploader.vue";
import MainLayout from "@/components/App/MainLayout.vue";


const routes = [
    {
        path: '/',
        name: 'HomeLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: HomeView,
                name: 'HomeMain'
            },
        ],
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterView
    },

    {
        path: '/auctions/new',
        name: 'CreateAuctionLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: CreateAuction,
                name: 'CreateAuction'
            },
        ],
    },

    {
        path: '/auctions/:auctionId',
        name: 'ViewActionLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: ViewAction,
                name: 'ViewAction'
            },
        ],
    },

    {
        path: '/auctions/:auctionId/edit',
        name: 'EditAuctionLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: EditAuction,
                name: 'EditAuction'
            },
        ],
    },


    {
        path: '/auctions/public',
        name: 'PublicAuctionsLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: PublicAuctions,
                name: 'PublicAuctions'
            },
        ],
    },

    {
        path: '/picture/testing',
        name: 'PictureTestingLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: PictureCrop,
                name: 'PictureTesting'
            },
        ],
    },
    {
        path: '/picture/drag',
        name: 'PictureDragLayout',
        component: MainLayout,
        children: [
            {
                path: '',
                component: ImageUploader,
                name: 'PictureDrag'
            },
        ],
    },

    // Add more routes here
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    const authStore = useAuthStore();
    const publicPages = ['/login', '/register'];
    const authNotRequired = publicPages.includes(to.path);
    const loggedIn = authStore.isProfileLoaded;

    if (loggedIn && authNotRequired) {
        return next('/'); // Redirect to the homepage
    }

    if (to.path === '/') {
        // Allow going to '/' if logged in or not
        return next();
    }

    // Trying to access a restricted page and not logged in
    if (!authNotRequired && !loggedIn) {
        return next('/login'); // Redirect to login page
    }

    next(); // Otherwise, proceed as normal
});


export default router