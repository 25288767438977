<template>
  <v-navigation-drawer
      class="elevation-3"
      v-model="drawer"
      :rail="rail"
      :permanent=true
      @click="rail = false"
  >
    <!-- Drawer content -->

    <!-- Vcard cu imagine -->
    <v-card class="mx-auto" max-width="400" rounded="0">
      <v-img
          class="align-end text-white"
          height="120"
          src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
          cover
      >
        <div>
          <v-list-item
              class="pb-4"
              :class="{ 'toggle-nav-drawer': rail }"
              prepend-avatar="https://randomuser.me/api/portraits/men/83.jpg"
              nav
              @click.stop="rail = !rail"
          >
            <template v-slot:append>
              <v-btn
                  variant="text"
                  :icon="mdiChevronLeft"
              ></v-btn>
            </template>
          </v-list-item>
        </div>
        <div class="list-item-container">
          <v-list-item
              :class="{ 'pl-0': rail }"
              nav
          >
            <template v-if="!rail">
              <span>{{ authName }}</span>
            </template>
            <template v-slot:append>
              <v-btn
                  variant="text"
                  size="small"
                  class="main-logout-button"
              >
                <v-icon size="x-large">{{ mdiPower }}"</v-icon>
                <v-tooltip
                    activator="parent"
                    location="top"
                >Logout
                </v-tooltip>
              </v-btn>

            </template>
          </v-list-item>

        </div>
      </v-img>
    </v-card>


    <!-- Sfarsit Vcard cu imagine -->
    <v-divider></v-divider>

    <v-list density="compact" nav>
      <v-list-item @click="goToHome" class="small-icon  text-black" :prepend-icon="mdiHomeCity" title="Acasa"
                   value="home"></v-list-item>


      <v-list-item :class="{ 'highlight-parent': isAuctionsOpen }" class="small-icon  text-black"
                   :prepend-icon="mdiGavel" @click="toggleAuctionsList"
                   value="auctions">
        <template #default>
          <div class="d-flex align-center justify-space-between">
            <div class="v-list-item-title">
              Licitatii
            </div>
            <div>
              <v-icon>{{ auctionsOpen ? mdiChevronUp : mdiChevronDown }}</v-icon>
            </div>
          </div>
        </template>
      </v-list-item>
      <v-list v-if="auctionsOpen">
        <!-- Additional list items for Licitatii -->
        <v-list-item class="custom-font-size small-icon  text-black" :prepend-icon="mdiInvoiceList"
                     title="Licitatiile mele"></v-list-item>
        <v-list-item class="custom-font-size  small-icon  text-black" :prepend-icon="mdiFormatListChecks"
                     title="Licitatiile la care am participat"></v-list-item>
        <v-list-item @click="goToPublicAuctions" class="custom-font-size  small-icon  text-black"
                     :prepend-icon="mdiGroup"
                     title="Licitatii publice"></v-list-item>
        <v-list-item @click="goToAddAuction" class="custom-font-size  small-icon  text-black"
                     :prepend-icon="mdiPlusCircleOutline"
                     title="Adauga licitatie"></v-list-item>
        <!-- Add more items as needed -->
      </v-list>


      <!-- Company list -->
      <v-list-item :class="{ 'highlight-parent': isCompanyOpen }" class="small-icon  text-black"
                   :prepend-icon="mdiDomain" @click="toggleCompanyList"
                   value="account">
        <template #default>
          <div class="d-flex  align-center justify-space-between">
            <div class="v-list-item-title">
              Companie
            </div>
            <div>
              <v-icon>{{ companyOpen ? mdiChevronUp : mdiChevronDown }}</v-icon>
            </div>
          </div>
        </template>
      </v-list-item>
      <v-list v-if="companyOpen">
        <!-- Additional list items for Licitatii -->
        <v-list-item class="custom-font-size small-icon  text-black" :prepend-icon="mdiInformationOutline"
                     title="Despre noi"></v-list-item>
        <v-list-item class="custom-font-size small-icon  text-black" :prepend-icon="mdiFormatListGroup"
                     title="Termeni si conditii"></v-list-item>
        <v-list-item class="custom-font-size small-icon  text-black" :prepend-icon="mdiCookieOutline"
                     title="Politica Cookies"></v-list-item>
        <v-list-item class="custom-font-size small-icon  text-black" :prepend-icon="mdiFlagOutline"
                     title="GDPR"></v-list-item>
        <!-- Add more items as needed -->
      </v-list>
      <!-- End Company list -->


      <v-list-item class="small-icon text-black" :prepend-icon="mdiAccountGroupOutline" title="Users"
                   value="users"></v-list-item>
      <v-list-item class="small-icon text-black" :prepend-icon="mdiAccountGroupOutline" @click="goToAddProfilePicture" title="Poza profil"
                   value="users"></v-list-item>
      <v-list-item class="small-icon text-black" :prepend-icon="mdiAccountGroupOutline" @click="goToAddPDrag" title="Drag si drop"
                   value="users"></v-list-item>
    </v-list>
  </v-navigation-drawer>

  <v-main>
    <router-view></router-view>
  </v-main>
  <!-- Footer -->
  <v-footer class="bg-black" padless>
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" class="text-center white--text">
          © {{ new Date().getFullYear() }} ArtCromatic
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
  <!-- END Footer -->
</template>

<script setup>
import {computed, ref} from 'vue';
import {mdiAccountGroupOutline} from "@mdi/js/commonjs/mdi";
import {mdiHomeCity} from "@mdi/js/commonjs/mdi";
import {mdiChevronLeft} from "@mdi/js/commonjs/mdi";
import {mdiChevronUp} from "@mdi/js/commonjs/mdi";
import {mdiChevronDown} from "@mdi/js/commonjs/mdi";
import {mdiGavel} from "@mdi/js/commonjs/mdi";
import {mdiDomain} from "@mdi/js/commonjs/mdi";
import {mdiInvoiceList} from "@mdi/js/commonjs/mdi";
import {mdiFormatListChecks} from "@mdi/js/commonjs/mdi";
import {mdiGroup} from "@mdi/js/commonjs/mdi";
import {mdiPlusCircleOutline} from "@mdi/js/commonjs/mdi";
import {mdiInformationOutline} from "@mdi/js/commonjs/mdi";
import {mdiFormatListGroup} from "@mdi/js/commonjs/mdi";
import {mdiCookieOutline} from "@mdi/js/commonjs/mdi";
import {mdiFlagOutline} from "@mdi/js/commonjs/mdi";
import {mdiPower} from "@mdi/js/commonjs/mdi";

import router from "@/router";
import {useAuthStore} from '@/store/authStore';

const drawer = ref(true);
const rail = ref(false);
const auth = useAuthStore();
const authName = computed(() => auth.name);
const isAuctionsOpen = computed(() => auctionsOpen.value);
const isCompanyOpen = computed(() => companyOpen.value);

const companyOpen = ref(false);

const toggleCompanyList = () => {
  companyOpen.value = !companyOpen.value;
};

const auctionsOpen = ref(false);

const toggleAuctionsList = () => {
  auctionsOpen.value = !auctionsOpen.value;
};

const goToAddAuction = () => {
  router.push({name: 'CreateAuction'});
};

const goToAddProfilePicture = () => {
  router.push({name: 'PictureTesting'});
};
const goToAddPDrag = () => {
  router.push({name: 'PictureDrag'});
};

const goToPublicAuctions = () => {
  router.push({name: 'PublicAuctions'});
};

const goToHome = () => {
  router.push({name: 'HomeMain'});
};
</script>

<style>
.v-list-item .v-list-item-title {
  font-size: 0.9rem !important;
}

.custom-font-size .v-list-item-title {
  font-size: 0.9rem !important;
}

.highlight-parent {
  background-color: #E0E0E0 !important; /* Choose your highlight color */
}

.list-item-container {
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */

}

.small-icon .v-icon {
  font-size: 22px; /* Or any other size you prefer */
  color: #000 !important;
}

.toggle-nav-drawer .v-avatar {
  min-width: 36px;
  height: auto !important;
}
</style>