<template>
  <v-container fill-height>
    <!-- Outer Card -->
    <v-card class="px-5 py-5">
      <v-card-text>
        <v-alert v-if="successMessageShown" type="success" dense class="mb-5">
          Auction created successfully!
        </v-alert>
        <div v-if="loading" class="loading-container">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>
        </div>
        <v-row justify="center"  v-else>

          <!-- Carousel Column -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <div>
              <v-carousel v-model="model" >
                <v-carousel-item
                    v-for="(item, i) in items"
                    :key="i"
                    :src="item.src"
                    cover
                    :rounded= 100
                ></v-carousel-item>
              </v-carousel>
              <div class="thumbnails">
                <div
                    v-for="(item, i) in items"
                    :key="i"
                    class="thumbnail"
                    :class="{ 'active': i === model }"
                    @click="setModel(i)"
                    :style="{ backgroundImage: 'url(' + item.src + ')' }"
                ></div>
              </div>
            </div>
          </v-col>

          <!-- Card Column -->
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-card class="mx-auto">
              <v-card-title>
                <h2 class="text-h4">

                  {{ localAuctionData?.title || 'Default Title' }}
                </h2>
                <v-spacer></v-spacer>
                <span class="text-h6">$44.50</span>
              </v-card-title>

              <v-card-text>
                {{ localAuctionData?.content || 'Default Description' }}
              </v-card-text>

              <v-divider class="mx-4"></v-divider>

              <v-card-text>
                <span class="subheading">Select size</span>

                <v-chip-group
                    v-model="selection"
                    selected-class="text-deep-purple-accent-4"
                    mandatory
                >
                  <v-chip
                      v-for="size in sizes"
                      :key="size"
                      :value="size"
                  >
                    {{ size }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>

              <v-card-actions>
                <v-btn
                    block
                    color="deep-purple-accent-4"
                >
                  Add to Cart
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script setup>
import {computed, onMounted, ref} from 'vue'
import { useRoute } from 'vue-router'
import { useAuctionsStore } from '@/store/auctions'
const loading = ref(true);
 const route = useRoute()
const auctionsStore = useAuctionsStore()
const localAuctionData = computed(() => auctionsStore.getAd) // Use a computed property
const auctionId = route.params.auctionId
console.log('auctionID', auctionId);
const successMessageShown = ref(false);
onMounted(async () => {
  successMessageShown.value = route.query.success === 'true';


  loading.value = true
  await auctionsStore.fetchSingleAuction(auctionId)
  loading.value = false
  console.log('Fetched Datasssss:', auctionsStore.getAd); // Check the fetched data
})

// Create a computed property that reflects the current state of auctionData in the store


const model = ref(0)
const items = [
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
  },
  {
    src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
  },
]

function setModel(index) {
  model.value = index
}

const sizes = [
  '04',
  '06',
  '08',
  '10',
  '12',
  '14',
]

const selection = ref('08')
</script>

<style scoped>
.thumbnails {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  overflow-x: auto;
  width: 100%;
}

.thumbnail {
  flex: 1 0 auto;
  height: 48px;
  cursor: pointer;
  margin-right: 8px;
  border: 2px solid transparent; /* No border by default */
  background-size: cover;
  background-position: center;
  min-width: 20%; /* Adjust as per requirement */
}

.thumbnail.active {
  border: 2px solid orange; /* Blue border for the active thumbnail */
}
.v-window.v-theme--light.v-carousel {
  border-radius: 10px;
}
</style>
