import { defineStore } from 'pinia';
import { useAuthStore } from '@/store/authStore';

export const useAuctionsStore = defineStore('auctions', {
    state: () => ({
        ad: null,
        auctions: [], // Add this line to store the fetched auctions

    }),
    getters: {
        getAd: (state) => {
            return state.ad;
        },
        // ... (any other getters)
    },
    actions: {
        async submitAuctionData(data) {
            const authStore = useAuthStore(); // Access the auth store
            const token = authStore.token; // Get the token

                const response = await fetch('https://apitest.artcromatic.ro/api/auctions/save', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `${token}` // Include the token in the header
                    },
                    body: JSON.stringify(data)
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.log('pe auctions salveaza',JSON.stringify(errorData) );
                    throw new Error(JSON.stringify(errorData)   || 'Error registering');
                }

                const responseData = await response.json();
                console.log('Submission Responsssssse PE AICI:', responseData);

                 return responseData;
                // You can update the state or do other actions based on the response
        },
        async fetchAuctions(page = 1, itemsPerPage = 10) {
            const authStore = useAuthStore(); // Access the auth store
            const token = authStore.token; // Get the token

            try {
                const response = await fetch(`https://apitest.artcromatic.ro/api/auctions?page=${page}&per_page=${itemsPerPage}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${token}` // Include the token in the header
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const auctions = await response.json();
                this.auctions = auctions.data; // Update the state with the fetched auctions
                console.log('Fetched Auctions aici din fetch:', auctions);
            } catch (error) {
                console.error('Error fetching auctions:', error);
                // Handle the error as needed
            }
        },
        async fetchSingleAuction(auctionId) {
            const authStore = useAuthStore(); // Access the auth store
            const token = authStore.token; // Get the token

            try {
                const response = await fetch(`https://apitest.artcromatic.ro/api/auctions/${auctionId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `${token}` // Include the token in the header
                    }
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const auctionData = await response.json();
                console.log('arrr', auctionData);
                this.ad = auctionData;
                console.log('thisAD', this.ad);
                // Update the state with the fetched auction

                console.log('Fetched Single Aucssstion:', this.ad);
            } catch (error) {
                console.error('Error fetching single auction:', error);
                // Handle the error as needed
            }
        },
        async deleteAuction(auctionId) {
            const authStore = useAuthStore(); // Access the auth store
            const token = authStore.token; // Get the token

            try {
                const response = await fetch(`https://apitest.artcromatic.ro/api/auctions/${auctionId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `${token}` // Include the token in the header
                    }
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error on deleting auction:', JSON.stringify(errorData));
                    throw new Error(JSON.stringify(errorData) || 'Error occurred during deletion');
                }

                const responseData = await response.json();
                console.log('Deletion Response:', responseData);
                // Optionally, update the state or do other actions based on the response
                this.auctions = this.auctions.filter(auction => auction.id !== auctionId);
            } catch (error) {
                console.error('Error deleting auction:', error);
                // Handle the error as needed
            }
        }
    }
});