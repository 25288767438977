<template>
  <v-card >
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5">Confirm Removal</v-card-title>
        <v-card-text>Are you sure you want to remove this image?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">No</v-btn>
          <v-btn color="red" text @click="confirmRemoval">Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card-text>
      <div>
        <input type="file" multiple ref="fileInput" @change="handleFilesUpload" style="display: none;" />
        <v-btn :icon="mdiPlus" size="x-large" @click="triggerFileInput">

        </v-btn>
        <draggable
            v-model="images"
            @start="drag=true"
            @end="drag=false"
            @update="onListChange"
            class="drag-area"
            item-key="id"
        >
          <template #item="{ element, index }">
            <v-card class="image-card ma-2">
              <v-card-text class="pa-2">
                <div class="image-container" :key="index">
                  <img :src="element.url" />
                  <v-btn class="remove-image-from-gallery" size="x-small" color="error" @click="promptRemove(index)" :icon="mdiDeleteOutline"></v-btn>
                </div>
              </v-card-text>
            </v-card>
          </template>
        </draggable>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from 'vue';
import draggable from 'vuedraggable';
import {mdiDeleteOutline, mdiPlus} from "@mdi/js";
export default {
  components: {
    draggable,
  },
  setup() {
    const images = ref([]);
    const fileInput = ref(null); // Add this
    const dialog = ref(false);
    const indexToRemove = ref(null);
    const handleFilesUpload = (event) => {
      const files = Array.from(event.target.files);
      console.log('Uploaded files:', files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          images.value.push({
            id: Date.now() + file.name,
            file: file, // Store the File object
            url: e.target.result // Base64 URL for displaying the image
          });
        };
        reader.readAsDataURL(file);
      });
    };

    const removeImage = (index) => {
      images.value.splice(index, 1);
    };

    const onListChange = () => {
      console.log('Reordered images:', images.value);
      // Logging File objects specifically
      console.log('Reordered files:', images.value.map(image => image.file));
    };

    const triggerFileInput = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    };
    const promptRemove = (index) => {
      indexToRemove.value = index;
      dialog.value = true;
    };

    const confirmRemoval = () => {
      if (indexToRemove.value !== null) {
        images.value.splice(indexToRemove.value, 1);
      }
      dialog.value = false;
    };
    return {
      images,
      handleFilesUpload,
      removeImage,
      onListChange,
      mdiDeleteOutline,
      fileInput, // Add this
      triggerFileInput,
      mdiPlus,
      dialog,
      promptRemove,
      confirmRemoval,
    };
  },
};
</script>

<style>
.drag-area {
  display: flex; /* Make the drag area a flex container */
  flex-wrap: wrap; /* Wrap items to the next line */
  align-items: center; /* Align items vertically */
  gap: 10px; /* Add gap between items */
}

.image-card {
  width: fit-content;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Optional: Adds shadow for better card appearance */
  transition: 0.3s; /* Optional: Adds transition effect for hover */
}

.image-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* Optional: Changes shadow on hover */
}


.image-container {
  position: relative; /* Set position relative for positioning the remove button absolutely within it */
  flex: 0 1 auto; /* Do not grow, but can shrink */
}

.image-container img {
  width: 200px; /* Adjusted width */
  height: auto; /* Height is auto to maintain aspect ratio */
  display: block; /* Make images block-level to avoid extra space below */
  border-radius: 5px; /* Optional: Adds rounded corners to the images */
  box-sizing: border-box; /* Add this to include border in the element's total width and height */
}

.remove-image-from-gallery {
  position: absolute !important; /* Absolute position for the button */
  top: 0 !important; /* Position at the top of the container */
  right: 0 !important; /* Position at the right of the container */
  z-index: 10 !important; /* Ensure the button is above the image */
  padding: 5px !important; /* Adjust padding if necessary */
  min-width: 0 !important; /* Remove the default min-width of v-btn */
}

/* Add styles for Vuetify components if needed */
</style>