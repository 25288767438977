<template>
  <v-container>
    <v-row justify="center" align="center" class="fill-height">
      <!-- Display error messages -->
      <v-col cols="12">
        <v-alert v-for="(error, index) in processedErrors" :key="index" type="error">
          <strong>{{ error.field }}:</strong>
          <div v-for="(message, index) in error.messages" :key="index">
            {{ message }}
          </div>
        </v-alert>
      </v-col>
      <v-col cols="12" sm="8" md="6">
        <v-card class="pa-5" elevation="2">
          <v-form ref="form" v-model="valid">
            <v-text-field
                v-model="auctionData.title"
                label="Title"
                :rules="[required]"
                required
            ></v-text-field>

            <v-textarea
                v-model="auctionData.content"
                label="Content"
                required
                :rules="[required]"
            ></v-textarea>

            <v-select
                v-model="auctionData.category_id"
                :items="categories"
                item-value="id"
                item-title="name"
                label="Category"
                required
                :rules="[required]"
            >

            </v-select>

            <v-select
                v-model="auctionData.currency"
                :items="currencies"
                label="Currency"
                required
                :rules="[required]"
            ></v-select>

            <v-text-field
                v-model="auctionData.start_price"
                label="Start Price"
                type="number"
                required
                :rules="[required]"
            ></v-text-field>

            <v-text-field
                v-model="auctionData.min_bid"
                label="Minimum Bid"
                type="number"
                required
                :rules="[required]"
            ></v-text-field>

            <v-text-field
                v-model="auctionData.max_bid"
                label="Maximum Bid"
                type="number"
                required
                :rules="[required]"
            ></v-text-field>

            <v-select
                v-model="auctionData.status"
                :items="statuses"
                item-value="value"
                item-title="text"
                label="Status"
                required
                :rules="[required]"
            ></v-select>

            <v-text-field
                v-model="auctionData.end_timestamp"
                label="End Timestamp"
                type="datetime-local"
                required
                :rules="[required]"
            ></v-text-field>

            <v-btn
                :loading="loading"
                :disabled="!valid"
                @click="submitForm"
            >
              Submit
            </v-btn>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import {computed, onMounted, ref} from 'vue';
import {useAuctionsStore} from '@/store/auctions';

const auctionData = ref({
  title: '',
  content: '',
  category_id: null,
  currency: '',
  start_price: null,
  min_bid: null,
  max_bid: null,
  status: null,
  end_timestamp: ''
});

const currencies = ['RON', 'USD', 'EUR']; // Add other currencies as needed
//const statuses = [1, 0]; // Assuming 1 for active, 0 for inactive
const valid = ref(false);
const form = ref(null);
const loading = ref(false);
const required = (value) => !!value || 'This field is required.';

const auctionsStore = useAuctionsStore();
const submitError = ref(""); // Reactive variable to store the error message
const processedErrors = computed(() => {
  return Object.entries(submitError.value).reduce((acc, [field, messages]) => {
    if (messages.length) {
      acc.push({ field, messages });
    }
    return acc;
  }, []);
});
const submitForm = async () => {
  submitError.value = "";
  loading.value = true; // Start loading

  if (valid.value && form.value.validate()) {


    // Convert end_timestamp to a Date object
    const endTimestampDate = new Date(auctionData.value.end_timestamp);

    // Construct the date parts, ensuring two digits for month, day, hour, minute, and second
    const year = endTimestampDate.getFullYear();
    const month = ('0' + (endTimestampDate.getMonth() + 1)).slice(-2); // getMonth() is zero-based
    const day = ('0' + endTimestampDate.getDate()).slice(-2);
    const hours = ('0' + endTimestampDate.getHours()).slice(-2);
    const minutes = ('0' + endTimestampDate.getMinutes()).slice(-2);
    const seconds = ('0' + endTimestampDate.getSeconds()).slice(-2);

    // Format the date in "YYYY-MM-DD HH:MM:SS"
    // Update the end_timestamp in auctionData before submitting
    auctionData.value.end_timestamp = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    console.log('pe aicea');
    console.log(auctionData.value);



    try {
      // Attempt to submit the auction data and catch the response
      const response = await auctionsStore.submitAuctionData(auctionData.value);

      console.log('aaaazzzz',response);

      // Handle the successful submission here
      console.log('Form submitted:', auctionData.value);
      console.log('Submission response:', response);

      // Optionally, you can clear the form or redirect the user to another page

    } catch (error) {
      // Parse the error message and set the submitError
      try {
        const errorObject = JSON.parse(error.message);

        console.log('sut aici cumva?', errorObject);

        if (errorObject) {
          console.log('sau pe acilea??????');


          submitError.value = errorObject;
        } else {
          // Handle unexpected error format
          submitError.value = { general: ["An unexpected error occurred"] };
        }
      } catch (e) {
        // Handle case where error message is not JSON
        submitError.value = { general: [error.message || "An unexpected error occurred"] };
      } finally {
        loading.value = false; // Stop loading regardless of success or error
      }
    }

  } else {
    loading.value = false; // Stop loading if validation fails
  }
};

let categories = ref([]);

const statuses = [
  { text: 'Active', value: 1 },
  { text: 'Inactive', value: 0 }
];


const fetchCategories = async () => {
  try {
    const response = await fetch('https://apitest.artcromatic.ro/api/categories');
    if (!response.ok) {
      throw new Error('Failed to fetch categories');
    }
    const data = await response.json();

    console.log('asta e data', data);
    // Assuming each category has 'id' and 'name' properties
    categories.value = data.map(cat => ({id: cat.id, name: cat.name}));    //categories = data
  } catch (error) {
    console.error('Error fetching categories:', error);
    // Handle error appropriately
  }
};

onMounted(() => {
  fetchCategories();
});
</script>

<style scoped>
/* Add your styles here */
</style>