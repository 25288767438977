import { defineStore } from 'pinia';
import router from '@/router'; // Import your router instance
export const useAuthStore = defineStore('auth', {
    state: () => ({
        token: localStorage.getItem('token') || null,
        name: localStorage.getItem('inf') ? atob(localStorage.getItem('inf')) : null, // Decode the name when retrieving
        isProfileLoaded: !!localStorage.getItem('token'),
    }),
    actions: {
        async login(email, password) {
            const response = await fetch('https://apitest.artcromatic.ro/api/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password }),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || response.statusText || 'Error logging in');
            }

            const data = await response.json();

            if (data.token) {
                this.setToken(data.token);
                this.setName(data.name); // Save the name to the store and localStorage
            }

            return data;
        },

        async register(name, email, password, password_confirmation) {
            const response = await fetch('https://apitest.artcromatic.ro/api/register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ name, email, password, password_confirmation }),
            });

            if (!response.ok) {
                console.log('testzz');
                const errorData = await response.json();
                console.log(JSON.stringify(errorData) );
                throw new Error(JSON.stringify(errorData)   || 'Error registering');
            }

            await router.push({path: '/login', query: { registered: 'true' }});
            console.log('de ce');
            return await response.json();
        },
        setToken(newToken) {
            this.token = newToken;
            localStorage.setItem('token', newToken);
            this.isProfileLoaded = !!newToken;
        },
        setName(newName) {
           const encodedName = btoa(newName); // Encode the name
            this.name = newName;
            localStorage.setItem('inf', encodedName); // Save the encoded name to localStorage
        },
        logout() {
            this.token = null;
            this.name = null;
            this.isProfileLoaded = false;
            localStorage.removeItem('token');
            localStorage.removeItem('inf');
            router.push('/login');
        },

    },
});