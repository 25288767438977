<template>
  <v-container>
    <div v-if="loading" class="loading-container">
      <v-progress-linear indeterminate color="primary"></v-progress-linear>
    </div>
    <v-card v-else class="mb-4" elevation="2">
      <v-card-title class="text-h5">Auction Listings</v-card-title>
      <v-card-text>
        <!-- Updated class for custom-gap -->
        <v-row   dense>
          <v-col v-for="auction in auctions" :key="auction.id" cols="12" sm="6" md="4" lg="2" class="pa-2 five-cards-per-row">
            <v-card class="public-auctions mb-4">
              <v-img
                  :src="getAuctionImage(auction)"
                  class="align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  height="250px"
              >
                <v-card-title class="text-white">{{ auction.title }}</v-card-title>
              </v-img>
              <v-card-text>
                {{ auction.content }}
              </v-card-text>
              <v-card-actions>
                <v-btn text  :icon="mdiEye" color="primary" @click="viewAuction(auction.id)" > </v-btn>
                <v-btn text   :icon="mdiDelete"  color="error" @click="deleteAuction(auction.id)"></v-btn>
                <v-btn text   :icon="mdiPencil"  color="success" @click="editAuction(auction.id)"> </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-pagination
        v-model="pagination.currentPage"
        :length="pagination.lastPage"
        @input="page => fetchAuctions(page)"
    ></v-pagination>

  </v-container>
</template>


<script setup>
import {ref, onMounted} from 'vue';
import {useRouter} from 'vue-router';
import {useAuctionsStore} from '@/store/auctions';
import {mdiEye} from "@mdi/js";
import {mdiDelete} from "@mdi/js";
import {mdiPencil} from "@mdi/js";

const router = useRouter();
const loading = ref(true);
const auctionsStore = useAuctionsStore();
const auctions = ref([]);

// LOGICA DE PAGINATIE
const pagination = ref({
  currentPage: 1,
  lastPage: 1,
  nextPageUrl: null,
  prevPageUrl: null,
  total: 0,
});


onMounted(async () => {
  loading.value = true;
  try {
    await auctionsStore.fetchAuctions();
    auctions.value = auctionsStore.auctions.map(auction => ({
      ...auction,
      statusText: auction.status === 1 ? 'Active' : 'Inactive',
      formattedCreatedAt: formatDateTime(auction.created_at),
      formattedEndTimestamp: formatDateTime(auction.end_timestamp)
    }));
  } catch (error) {
    console.error('Failed to fetch auctions:', error);
  } finally {
    loading.value = false;
  }
});

function formatDateTime(dateTimeString) {
  const dateObj = new Date(dateTimeString);
  const day = dateObj.getDate().toString().padStart(2, '0');
  const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours().toString().padStart(2, '0');
  const minutes = dateObj.getMinutes().toString().padStart(2, '0');
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

function getAuctionImage(auction) {
  // Replace with your logic to determine the auction image
  return auction.image || 'https://cdn.vuetifyjs.com/images/cards/road.jpg';
}

function viewAuction(auctionId) {

  router.push({path: `/auctions/${auctionId}`});

}

function editAuction(auctionId) {
  router.push({name: 'EditAuction', params: {auctionId}});
}

async function deleteAuction(auctionId) {
  try {
    await auctionsStore.deleteAuction(auctionId);
    auctions.value = auctions.value.filter(auction => auction.id !== auctionId);
  } catch (error) {
    console.error('Error deleting auction:', error);
  }
}
</script>

<style scoped>
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.public-auctions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.five-cards-per-row {
  flex: 0 0 20%;
  max-width: 20%;
}
@media (max-width: 1200px) { /* Adjust based on when you want to switch to fewer cards per row */
  .five-cards-per-row {
    flex: 0 0 25%; /* 4 cards per row */
    max-width: 25%;
  }
}
@media (max-width: 960px) { /* Adjust for smaller screens */
  .five-cards-per-row {
    flex: 0 0 33.33%; /* 3 cards per row */
    max-width: 33.33%;
  }
}
@media (max-width: 600px) { /* Adjust for extra small screens */
  .five-cards-per-row {
    flex: 0 0 100%; /* 1 card per row */
    max-width: 100%;
  }
}
</style>
