<template>
  <v-container>
    <v-row justify="center" align="center" style="height: 100vh;">
      <v-col cols="12" sm="8" md="6" lg="4">
        <v-snackbar
            v-model="snackbar"
            :color="snackbarType === 'error' ? 'red' : 'green'"
            :timeout="1000000"
            class="top-snackbar"
            top
        >
          <div class="d-flex justify-space-between align-center" style="width: 100%;">
            <span>{{ snackbarMessage }}</span>
            <v-btn variant="text" @click="snackbar = false">X</v-btn>
          </div>
        </v-snackbar>
        <div class="text-center">
          <div>
            <v-img
                class="mx-auto my-6"
                max-width="100"
                src="https://artcromatic.s3.eu-central-1.amazonaws.com/logos/logo-artcromatic@2x.svg"
            ></v-img>
            <v-card
                class="mx-auto pa-4 pb-8"
                elevation="8"
                max-width="448"
                rounded="lg"
            >
              <v-card-title class="text-h5 text-center">Inregistrare</v-card-title>
              <form @submit.prevent="onSubmit">
                <div class="text-subtitle-1 text-medium-emphasis text-left"><span class="text-black">Nume</span></div>
                <v-text-field
                    v-model="name"
                    density="compact"
                    placeholder="Nume"
                    variant="outlined"
                    :prepend-inner-icon="mdiAccount"
                    :rules="nameRules"
                ></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis text-left"><span class="text-black">E-mail</span></div>
                <v-text-field
                    v-model="email"
                    density="compact"
                    placeholder="E-mail"
                    variant="outlined"
                    :prepend-inner-icon="mdiEmailOutline"
                    :rules="emailRules"
                ></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis text-left"><span class="text-black">Parola</span></div>
                <v-text-field
                    v-model="password"
                    density="compact"
                    placeholder="Introdu parola"
                    variant="outlined"
                    type="password"
                    :prepend-inner-icon="mdiLock"
                    :rules="passwordRules"
                ></v-text-field>

                <div class="text-subtitle-1 text-medium-emphasis text-left"><span
                    class="text-black">Repeta parola</span></div>
                <v-text-field
                    v-model="password_confirmation"
                    density="compact"
                    placeholder="Repeta parola"
                    variant="outlined"
                    type="password"
                    :prepend-inner-icon="mdiLockQuestion"
                ></v-text-field>

                <v-btn
                    type="submit"
                    block
                    class="mb-8 font-weight-bold"
                    color="orange"
                    size="large"
                    variant="tonal"
                    :loading="isLoading"
                    :disabled="!canSubmit"
                >
                  Trimite
                </v-btn>

                <v-card-text class="text-center">
                  <router-link
                      class="text-orange text-decoration-none"
                      to="/login"
                  >
                    Du-te spre autentificare
                    <v-icon :icon="mdiChevronRight"></v-icon>
                  </router-link>
                </v-card-text>
              </form>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import {computed, ref} from 'vue';
import {useAuthStore} from '@/store/authStore';
import {mdiAccount, mdiChevronRight, mdiEmailOutline, mdiLock, mdiLockQuestion} from '@mdi/js';

const auth = useAuthStore();
const name = ref('');
const email = ref('');
const password = ref('');
const password_confirmation = ref('');
const isLoading = ref(false);
const snackbar = ref(false);
const snackbarMessage = ref('');
const snackbarType = ref('');

const canSubmit = computed(() => {
  return name.value.trim() && email.value.trim() && password.value.trim() && password_confirmation.value.trim();
});

const nameRules = [v => !!v || 'Numele este obligatoriu'];
const emailRules = [
  v => !!v || 'Adresa de e-mail este obligatorie',
  v => /.+@.+\..+/.test(v) || 'Adresa de e-mail trebuie sa fie valida'
];
const passwordRules = [v => !!v || 'Parola este obligatorie'];
const onSubmit = async () => {
  isLoading.value = true;
  try {
    const response = await auth.register(name.value, email.value, password.value, password_confirmation.value);
    snackbarMessage.value = "Inregistrarea a fost efectuata cu succes";
    snackbarType.value = 'success';
    // Additional logic for successful registration
    console.log(response);
  } catch (error) {
    console.log('Error in component:', error);

    let errorMessage = 'An unknown error occurred'; // Default error message
    try {
      // Parse the error message and process if it's an object
      const errors = JSON.parse(error.message);
      if (typeof errors === 'object' && errors !== null) {
        // Create a combined string of all error messages
        errorMessage = Object.entries(errors)
            .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join(', ') : value}`)
            .join('. ');
      }
    } catch (parseError) {
      // If parsing fails, use the original error message
      errorMessage = error.message || errorMessage;
    }

    snackbarMessage.value = errorMessage;
    snackbarType.value = 'error';
  } finally {
    isLoading.value = false;
    snackbar.value = true;
  }
};
</script>

<style scoped>
.top-snackbar .v-snackbar--variant-elevated {
  position: fixed;
  bottom: 100% !important;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000; /* Ensure it's above other content */
}
</style>